<template>
  <div :style="menuBaseStyle" class="">
    <v-row no-gutters>
      <v-col cols="6" class="px-6 cursor-pointer" @click="openIOP">
        <div class="d-flex align-start justify-center">
          <img src="../assets/own/IOP-cirkel.svg" height="40px" alt="Individueel Ontwikkel Plan">
        </div>
        <div class="text-blue d-flex align-end justify-center text-center">
          Individueel Ontwikkel Plan
        </div>
      </v-col>

      <v-col cols="6" class="px-6 cursor-pointer" @click="openTOP">
        <div class="d-flex align-start justify-center">
          <img src="../assets/own/TOP-cirkel.svg" height="40px" alt="Team Ontwikkel Plan">
        </div>
        <div class="text-blue d-flex align-end justify-center text-center">
          Team Ontwikkel Plan
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>


export default {
  name: "FooterWise",
  data() {
    return {
      menuBaseStyle: {
        'background-color': this.$vuetify.theme.themes.light.white,
        'height': '110px',
        'padding-top': '10px',
        'position': 'fixed',
        'bottom': '5px',
        'width': '100%',
        'z-index': '1000',
      },
      menuBaseLogoStyle: {
        'height': '40px'
      }
    }
  },
  methods: {
    openIOP() {
      this.$router.push({name: "dashboardWiseIOPBase"})
    },
    openTOP() {
      this.$router.push({name: "dashboardWiseTOPBase"})
    },
  }
};
</script>

<style scoped>
.text-blue {
  color: #004572;
}
</style>