<template>
  <v-app>
    <v-main>
      <menu-dashboard />

      <div style="margin-top: 80px; margin-bottom: 80px">
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<script>

import MenuDashboard from "@/menu/dashboard";

import FooterWise from "@/footer/wise";

export default {
  name: "layoutIOP",

  components: {
    FooterWise,
    MenuDashboard,

  }
};
</script>
